function Reply_by_bot_dropdown({ lang, bot, toggleReplyByBotStatus }) {
  let actionList = [
    {
      action: "",
      text: "__business_hours",
    },
    {
      action: "Y",
      text: "__always_on",
    },
    {
      action: "N",
      text: "__always_off",
    },
  ];

  return (
    <div>
      <div class="dropdown sp-dropdown">
        <div
          class="dropdown-button dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="true"
          style={{ height: "42px", borderRadius: "6px", }}
        >
          <div class="text" style={{ fontSize: "smaller" }}>
            {
              lang[
                `${
                  bot === ""
                    ? "__business_hours"
                    : bot === "Y"
                    ? "__always_on"
                    : "__always_off"
                }`
              ]
            }
          </div>
          <i class="icon icon-angle-down" style={{ fontSize: '14px', marginRight: 'unset'}}></i>
        </div>
        <div class="dropdown-menu sp-list">
          {actionList.map((item) => {
            return (
              <div
                class="item -clickable sp-row -gap"
                onClick={() => toggleReplyByBotStatus(item.action)}
                key={item.action}
              >
                <div class="sp-col">
                  <p class="sp-text" style={{ fontSize: "smaller" }}>{lang[item.text]}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
